<template>
  <tr
    class="
      gap-3xs
      flex items-center
      rounded border-2 border-surface-primary bg-surface-primary
      hover:bg-surface-secondary hover:border-surface-secondary
      active:bg-blurple-200 active:border-blurple-200
    "
  >
    <slot />
  </tr>
</template>
